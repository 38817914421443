import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarni moduli - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarni moduli služe za brtvljenje instalacija kroz granice požarnih sektora. Protupožarne djelimo na module s okvirom i bez okvira.',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarni-moduli/`, name: 'Protupožarni moduli'},
      ]}
    />
    <Hero
      description="Protupožarni moduli služe za brtvljenje instalacija kroz granice požarnih sekotra. Protupožarne djelimo na module s okvirom i bez okvira."
      slim
      subtitle="Protupožarni moduli"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarni-moduli" />
  </Page>
)

export default Category